.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 100px;
}

.questionsAndAnswers {
  margin-top: 32px;
  max-width: 900px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .containerInner {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  .questionsAndAnswers {
    margin-top: 32px;
  }
}
