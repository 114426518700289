.containerInner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.containerResponsive {
  align-items: center;
  display: flex;
  height: 100%;
}

.containerOuterGradient {
  background: linear-gradient(180deg, #005445 3.64%, rgba(18, 121, 103, 0) 100%);
  box-sizing: border-box;
  padding-bottom: 50px;
  padding-top: 30px;
}

.containerOuterSolid {
  background-color: var(--color-green);
  padding-bottom: 24px;
  padding-top: 30px;
}

.header {
  color: var(--color-yellowLight);
  margin: 0;
  text-transform: uppercase;

  font-family: "TanNimbus", serif;
  font-size: 32px;
}

@media only screen and (max-width: 640px) {
  .header {
    font-size: 26px;
  }

  .logoContainer {
    width: 152px;
  }
}
