.backgroundColorBackgroundOverlay {
  background-color: var(--color-backgroundOverlay);
}

.backgroundColorDimmer {
  background-color: var(--color-dimmer);
}

.backgroundColorError {
  background-color: var(--color-error);
}

.backgroundColorGreen {
  background-color: var(--color-green);
}

.backgroundColorGreenDark {
  background-color: var(--color-greenDark);
}

.backgroundColorGreenMuted {
  background-color: var(--color-greenMuted);
}

.backgroundColorPink {
  background-color: var(--color-pink);
}

.backgroundColorPinkHover {
  background-color: var(--color-pinkHover);
}

.backgroundColorWhite {
  background-color: var(--color-white);
}

.backgroundColorYellowLight {
  background-color: var(--color-yellowLight);
}

.backgroundColorYellowLightHover {
  background-color: var(--color-yellowLightHover);
}

