.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.description {
  margin-top: 8px;
  max-width: 206px;
}

.pfp {
  border-radius: 24px;
  height: 220px;
  width: 220px;
}

.smallDescription {
  color: var(--color-yellowLight);
  margin-top: 4px;
  text-align: center;

  font-family: "Facundo", sans-serif;
  font-size: 13px;
  font-weight: bold;
}

.title {
  margin-top: 16px;
  max-width: 220px;
}