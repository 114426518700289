body {
  background-color: white;
  height: 100%;
  margin: 0px;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

.buttonPlain {
  border-style: none;
  outline: none;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.buttonPlain:hover {
  cursor: pointer;
}

/* See https://stackoverflow.com/questions/1833734/display-inline-block-extra-margin/8262649#8262649 */
.hideText {
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
}

:root {
  --height-pfpSpritesheet: 387px;
}


@media only screen and (max-width: 1800px) {
  :root {
    --height-pfpSpritesheet: 340px;
  }
}


@media only screen and (max-width: 768px) {
  :root {
    --height-pfpSpritesheet: 300px;
  }
}

@media only screen and (max-width: 640px) {
  :root {
    --height-pfpSpritesheet: 230px;
  }
}