@font-face {
  font-family: "Facundo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../../static/fonts/facundo/Facundo-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Facundo";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../../static/fonts/facundo/Facundo-SemiBold.otf) format("opentype");
}

@font-face {
  font-family: "Facundo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../../static/fonts/facundo/Facundo-Bold.otf) format("opentype");
}
