.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 100px;
  row-gap: 24px;
}

.description {
  max-width: 784px;
}

@media only screen and (max-width: 768px) {
  .containerInner {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}