:root {
  --color-backgroundOverlay: rgba(0, 0, 0, 0.3);
  --color-dimmer: rgba(0, 0, 0, 0.36);
  --color-error: #ff7777;
  --color-green: #127967;
  --color-greenDark: #024236;
  --color-greenMuted: #69B3A6;
  --color-pink: #FFC6C6;
  --color-pinkHover: #ffb4b4;
  --color-white: #ffffff;
  --color-yellowLight: #FEFCD7;
  --color-yellowLightHover: #fffcc3;
}
