:root {
  /* body1 */
  --font-family-body1: "Facundo", sans-serif;
  --font-size-body1: 24px;
  --font-weight-body1: 400;
  --line-height-body1: 32px;

  /* body2 */
  --font-family-body2: "Facundo", sans-serif;
  --font-size-body2: 20px;
  --font-weight-body2: 400;
  --line-height-body2: 27px;

  /* body3 */
  --font-family-body3: "Facundo", sans-serif;
  --font-size-body3: 16px;
  --font-weight-body3: 400;
  --line-height-body3: 21px;

  /* buttonText */
  --font-family-buttonText: "Facundo", sans-serif;
  --font-size-buttonText: 24px;
  --font-weight-buttonText: 700;
  --line-height-buttonText: 32px;

  /* header1 */
  --font-family-header1: "TanNimbus", serif;
  --font-size-header1: 72px;
  --font-weight-header1: 400;

  /* header2 */
  --font-family-header2: "TanNimbus", serif;
  --font-size-header2: 48px;
  --font-weight-header2: 400;
  /* TODO: tweak */
  --letter-spacing-header2: 1px;

  /* navLink */
  --font-family-navLink: "Facundo", sans-serif;
  --font-size-navLink: 18px;
  --font-weight-navLink: 700;
  /* TODO: tweak */
  --letter-spacing-navLink: 1px;
  --line-height-navLink: 24px;
  --text-transform-navLink: uppercase;
}

@media only screen and (max-width: 1800px) {
  :root {
    /* header1 */
    --font-size-header1: 64px;
  }
}

@media only screen and (max-width: 768px) {
  :root {
    /* body1 */
    --font-size-body1: 17px;
    --line-height-body1: 23px;

    /* body2 */
    --font-size-body2: 16px;
    --line-height-body2: 21px;

    /* body3 */
    --font-size-body3: 14px;
    --line-height-body3: 19px;

    /* buttonText */
    --font-size-buttonText: 17px;
    --line-height-buttonText: 23px;

    /* header1 */
    --font-size-header1: 34px;

    /* header2 */
    --font-size-header2: 28px;
    /* TODO: tweak */
    --letter-spacing-header2: 1px;

    /* navLink */
    --font-size-navLink: 16px;
    /* TODO: tweak */
    --letter-spacing-navLink: 1px;
    --line-height-navLink: 21px;
  }
}
