.container {
  column-gap: 36px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}


@media only screen and (max-width: 1200px) {
  .container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}