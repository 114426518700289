.answer {
  margin-top: 12px;
}

.chevron {
  flex-shrink: 0;
}

.question {
  padding-right: 12px;
  text-align: left;
}

.questionAnswerContainer {
  margin-top: 32px;
}

.questionAnswerContainer:first-child {
  margin-top: 0;
}

.questionContainer {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.questionContainer:hover {
  cursor: pointer;
}
