.buttons {
  align-items: center;
  column-gap: 64px;
  display: flex;
  margin-top: 36px;
}

.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  padding-top: 60px;
}

.description {
  margin-top: 16px;
}

.icons {
  align-items: center;
  column-gap: 60px;
  display: flex;
  margin-top: 32px;
}

.muted {
  opacity: 0.36;
}

.terms {
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {
  .containerInner {
    padding-bottom: 50px;
    padding-top: 40px;
  }
}

@media only screen and (max-width: 535px) {
  .buttons {
    flex-direction: column;
    margin-top: 24px;
    row-gap: 24px;
  }
}