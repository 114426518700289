.chevronDown {
  bottom: 0px;
  height: 24px;
  left: -1px;
  position: absolute;
  transform: translate(-50%, 50%);
  width: 24px;
}

.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 106px;
  padding-top: 106px;
}

.containerOuter {
  background-color: var(--color-green);
}

.description {
  margin-top: 26px;
  max-width: 758px;
}

.roadmapDate {
  letter-spacing: 1px;
}

.roadmapItem {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  row-gap: 4px;
}

.roadmapItemImage, .roadmapItemVideo {
  border-radius: 24px;
  margin-top: 24px;
  position: relative;
  width: 100%;
}

.roadmapItemVideo {
  padding-top: 56.25%;
}

.roadmapItems {
  border-left: 2px dashed var(--color-yellowLight);
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding-left: 48px;
  position: relative;
  row-gap: 56px;
}

.startDiamond {
  background-color: var(--color-yellowLight);
  height: 9px;
  left: -1px;
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 9px;
}

@media only screen and (max-width: 768px) {
  .containerInner {
    padding-bottom: 60px;
    padding-top: 80px;
  }

  .roadmapItems {
    padding-left: 38px;
    row-gap: 42px;
  }
}