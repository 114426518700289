.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 98px;
  padding-top: 98px;
}

.description {
  margin-top: 24px;
  max-width: 800px;
}

.teamMemberProfile {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.teamMemberProfileDescription {
  margin-top: 8px;
}

.teamMemberProfileImage {
  border-radius: 50%;
  height: 220px;
  object-fit: cover;
  width: 220px;
}

.teamMemberProfileTwitter {
  margin-top: 16px;
}

.teamMemberProfiles {
  column-gap: 146px;
  display: flex;
  margin-top: 58px;
}

@media only screen and (max-width: 1280px) {
  .teamMemberProfiles {
    column-gap: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .teamMemberProfiles {
    column-gap: 80px;
  }

  .teamMemberProfile, .teamMemberProfileImage {
    width: 180px;
  }

  .teamMemberProfileImage {
    height: 180px;
  }
}

@media only screen and (max-width: 768px) {
  .containerInner {
    padding-bottom: 60px;
    padding-top: 80px;
  }

  .teamMemberProfiles {
    column-gap: 60px;
  }

  .teamMemberProfile, .teamMemberProfileImage {
    width: 160px;
  }

  .teamMemberProfileImage {
    height: 160px;
  }
}

@media only screen and (max-width: 640px) {
  .teamMemberProfiles {
    align-items: center;
    flex-direction: column;
    row-gap: 50px;
  }
}