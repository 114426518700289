.containerOuter {
  background-color: var(--color-green);
}

.definition {
  margin-top: 24px;
}

.description {
  margin-top: 20px;
  max-width: 815px;
}

.originArt {
  height: 422px;
  left: 50%;
  object-fit: contain;
  object-position: center bottom;
  position: absolute;
  transform: translateX(-50%);
}

.originArtContainer {
  height: 422px;
  margin-top: 36px;
  position: relative;
  overflow: hidden;
}

.originArtGradient {
  background: linear-gradient(180deg, var(--color-green), 65%, #FEFCD7 100%);
  height: 422px;
  position: absolute;
  width: 100%;
}

.top {
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .originArt, .originArtContainer, .originArtGradient {
    height: 322px;
  }
}

@media only screen and (max-width: 640px) {
  .originArt, .originArtContainer, .originArtGradient {
    height: 222px;
  }
}