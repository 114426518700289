.button {
  margin-top: 24px;
  padding: 24px 36px !important;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.description {
  max-width: 850px;
}

@media only screen and (max-width: 640px) {
  .button {
    margin-top: 20px;
  }
}