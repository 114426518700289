.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 100px;
}

.containerOuter {
  background-color: var(--color-greenDark);
}

.description {
  margin-top: 24px;
  max-width: 725px;
}

.pfps {
  margin-top: 53px;
}

@media only screen and (max-width: 768px) {
  .containerInner {
    padding-bottom: 60px;
  }
}