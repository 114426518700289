@keyframes fade-in {
  from {
    top: 40px;
    opacity: 0;
  }

  to {
    top: 0px;
    opacity: 1;
  }
}

.animationInitial {
  opacity: 0;
  position: relative;
}

.fadeIn {
  animation-duration: 1.0s;
  animation-fill-mode: forwards;
  animation-name: fade-in;
  opacity: 1;
  position: relative;
}
