@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-duration: 2.0s;
  animation-fill-mode: forwards;
  animation-name: fade-in;
  opacity: 1;
  position: relative;
}

.bg {
  position: absolute;
  width: 100%;
}

.bgImage {
  height: 850px;
  object-position: center bottom;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.containerOuter {
  background-color: var(--color-green);
  position: relative;  
}

.ctaButton {
  margin-top: 32px;
}

.description {
  /* background-color: rgba(0,0,0,0.3); */
  border-radius: 24px;
  margin-top: 20px;
  max-width: 732px;
  /* padding: 16px; */
}

.gradient {
  background: linear-gradient(180deg, rgba(18, 121, 103, 0) 3.64%, #127967 100%);
  height: 142px;
  margin-top: -142px;
  position: relative;
  width: 100%;
  z-index: 5;
}

.header {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.hero {
  align-items: center;
  display: flex;
  flex-direction: column; 
  /* Accounts for header */
  padding-top: 140px;
  position: relative;
  z-index: 6;
}

.title {
  margin-top: 52px;
  text-align: center;
}

@media only screen and (max-width: 1800px) {
  .hero {
    /* Accounts for header */
    padding-top: 140px;
  }

  .title {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1536px) {
  .bgImage {
    height: 700px;
  }
}

@media only screen and (max-width: 768px) {
  .bgImage {
    height: 500px;
  }

  .hero {
    /* Accounts for header */
    padding-top: 130px;
  }
}

@media only screen and (max-width: 640px) {
  .ctaButton {
    margin-top: 24px;
  }

  .hero {
    /* Accounts for header */
    padding-top: 110px;
  }
}