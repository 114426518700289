.image {
  height: 300px;
  width: 300px;
}

@media only screen and (max-width: 640px) {
  .image {
    height: 180px;
    width: 180px;
  }
}