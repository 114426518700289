.button {
  align-items: center;
  background-color: transparent;
  border-style: none;
  outline: none;
  padding: 0;
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.iconContainer {
  margin-right: 12px;
}

/* Themes */

/* Green */
.buttonGreen,
.buttonGreenActive {
  color: var(--color-green);
}

/* Green Dark */
.buttonGreenDark,
.buttonGreenDarkActive {
  color: var(--color-greenDark);
}

/* Yellow Light */
.buttonYellowLight,
.buttonYellowLightActive {
  color: var(--color-yellowLight);
}