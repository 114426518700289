.body1Font {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: var(--font-weight-body1);
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
  text-transform: var(--text-transform-body1);
}

.body1MediumFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
  text-transform: var(--text-transform-body1);
}

.body1SemiBoldFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
  text-transform: var(--text-transform-body1);
}

.body1BoldFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
  text-transform: var(--text-transform-body1);
}

.body2Font {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: var(--font-weight-body2);
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
  text-transform: var(--text-transform-body2);
}

.body2MediumFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
  text-transform: var(--text-transform-body2);
}

.body2SemiBoldFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
  text-transform: var(--text-transform-body2);
}

.body2BoldFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
  text-transform: var(--text-transform-body2);
}

.body3Font {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: var(--font-weight-body3);
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
  text-transform: var(--text-transform-body3);
}

.body3MediumFont {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
  text-transform: var(--text-transform-body3);
}

.body3SemiBoldFont {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
  text-transform: var(--text-transform-body3);
}

.body3BoldFont {
  font-family: var(--font-family-body3);
  font-size: var(--font-size-body3);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body3);
  line-height: var(--line-height-body3);
  text-transform: var(--text-transform-body3);
}

.buttonTextFont {
  font-family: var(--font-family-buttonText);
  font-size: var(--font-size-buttonText);
  font-weight: var(--font-weight-buttonText);
  letter-spacing: var(--letter-spacing-buttonText);
  line-height: var(--line-height-buttonText);
  text-transform: var(--text-transform-buttonText);
}

.header1Font {
  font-family: var(--font-family-header1);
  font-size: var(--font-size-header1);
  font-weight: var(--font-weight-header1);
  letter-spacing: var(--letter-spacing-header1);
  line-height: var(--line-height-header1);
  text-transform: var(--text-transform-header1);
}

.header2Font {
  font-family: var(--font-family-header2);
  font-size: var(--font-size-header2);
  font-weight: var(--font-weight-header2);
  letter-spacing: var(--letter-spacing-header2);
  line-height: var(--line-height-header2);
  text-transform: var(--text-transform-header2);
}

.navLinkFont {
  font-family: var(--font-family-navLink);
  font-size: var(--font-size-navLink);
  font-weight: var(--font-weight-navLink);
  letter-spacing: var(--letter-spacing-navLink);
  line-height: var(--line-height-navLink);
  text-transform: var(--text-transform-navLink);
}

