.center {
  display: flex;
  justify-content: flex-start;
}

.container {
  align-items: center;
  column-gap: 120px;
  display: flex;
  justify-content: space-between;
  /* display: grid; */
  /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
  padding-top: 36px;
  position: relative;
  z-index: 10;
}

.containerOuterGradient {
  background: linear-gradient(180deg, #005445 3.64%, rgba(18, 121, 103, 0) 100%);
  padding-bottom: 81px;
}

.containerOuterSolid {
  background-color: var(--color-green);
  padding-bottom: 24px;
}

.header {
  color: var(--color-yellowLight);
  margin: 0;
  text-transform: uppercase;

  font-family: "TanNimbus", serif;
  font-size: 36px;
}

.navButtons, .icons {
  align-items: center;
  column-gap: 60px;
  display: flex;
  justify-content: center;
}

.icons {
  justify-content: flex-end;
}

@media only screen and (max-width: 1480px) {
  .navButtons, .icons {
    column-gap: 40px;
  }
}

@media only screen and (max-width: 1280px) {
  .container {
    column-gap: 60px;
  }

  .header {
    font-size: 32px;
  }
}