.body {
  align-items: center;
  background-color: var(--color-green);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 64px;
}

.containerOuter {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.countdown {
  font-family: "Space Mono", monospace;
  font-size: 28px;
  line-height: 32px;
  margin-top: 64px;
}

.description {
  margin-top: 32px;
}

.descriptionItems {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  row-gap: 12px;
}

.info {
  margin-top: 0px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 800px) {
  .countdown {
    font-size: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .body {
    padding-top: 24px;
  }

  .countdown {
    margin-top: 32px;
  }
}

.mintButton {
  margin-bottom: 32px;
}

.description {
  margin-top: 20px;
  max-width: 732px;
}

@media only screen and (max-width: 640px) {
  .mintButton {
    margin-top: 24px;
  }
}
