.container {
  background-color: var(--color-green);
  display: flex;
  justify-content: center;
  padding-bottom: 84px;
  padding-top: 64px;
}

.image {
  background-image: url(../../../static/images/curly.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  max-width: 900px;
  width: 90%;
}

@media only screen and (max-width: 640px) {
  .container {
    padding-bottom: 52px;
    padding-top: 44px;
  }

  .image {
    background-image: url(../../../static/images/curly-mobile.svg);
    height: 10px;
  }
}