.colorBackgroundOverlay {
  color: var(--color-backgroundOverlay);
}

.colorDimmer {
  color: var(--color-dimmer);
}

.colorError {
  color: var(--color-error);
}

.colorGreen {
  color: var(--color-green);
}

.colorGreenDark {
  color: var(--color-greenDark);
}

.colorGreenMuted {
  color: var(--color-greenMuted);
}

.colorPink {
  color: var(--color-pink);
}

.colorPinkHover {
  color: var(--color-pinkHover);
}

.colorWhite {
  color: var(--color-white);
}

.colorYellowLight {
  color: var(--color-yellowLight);
}

.colorYellowLightHover {
  color: var(--color-yellowLightHover);
}

