.clouds {
  background-color: var(--color-pink);
  width: 100%;
}

.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 84px;
  padding-top: 64px;
}

.ctaButton {
  margin-top: 32px;
}

.description {
  margin-top: 24px;
  max-width: 715px;
}

@media only screen and (max-width: 768px) {
  .containerInner {
    padding-bottom: 64px;
  }
}

@media only screen and (max-width: 640px) {
  .ctaButton {
    margin-top: 24px;
  }
}