.button {
  align-items: center;
  border-radius: 16px;
  border-style: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 16px;
  outline: none;
  text-decoration: none;
}

.button:disabled,
.disabled {
  cursor: default;
  opacity: 0.48;
  pointer-events: none;
}

.buttonAutoWidth {
  padding: 16px 24px;
}

.button100Width {
  width: 100%;
}

.button:enabled:hover {
  cursor: pointer;
}

.icon {
  /* Fixes https://github.com/vercel/next.js/issues/21914 */
  font-size: 0px;
  line-height: 0px;
}

.iconLeft {
  margin-right: 12px;
}

.iconRight {
  margin-left: 12px;
}

.linkContent:hover {
  cursor: pointer;
}

/*
 * Themes
 */

/* Pink theme */

.pinkTheme,
.pinkThemeLink {
  background-color: var(--color-pink);
  color: var(--color-greenDark);
}

.pinkTheme:enabled:active,
.pinkThemeLink:active {
  background-color: var(--color-pinkHover);
}

@media (hover: hover) {
  .pinkTheme:enabled:hover,
  .pinkThemeLink:hover {
    background-color: var(--color-pinkHover);
  }
}

/* Yellow light theme */

.yellowLightTheme,
.yellowLightThemeLink {
  background-color: var(--color-yellowLight);
  color: var(--color-green);
}

.yellowLightTheme:enabled:active,
.yellowLightThemeLink:active {
  background-color: var(--color-yellowLightHover);
}

@media (hover: hover) {
  .yellowLightTheme:enabled:hover,
  .yellowLightThemeLink:hover {
    background-color: var(--color-yellowLightHover);
  }
}
